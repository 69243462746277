<template>
    <div>
        <div v-if="$route.query.code === 'event'">
            <v-tabs v-model="tab" hide-slider height="auto" class="v-tabs--primary tab--block">
                <v-tab @click="isEvent(true)">진행중인 이벤트</v-tab>
                <v-tab @click="isEvent(false)">종료된 이벤트</v-tab>
            </v-tabs>
        </div>

        <div v-if="$route.query.code === 'volunteer' || $route.query.code === 'event' || $route.query.code === 'fair' || $route.query.code === 'newsletter' || $route.query.code === 'library'">
            <v-row class="board-contents">
                <v-col cols="6" md="4" v-for="(board, idx) in boards" :key="idx">
                    <v-card flat tile @click="show(board)" class="h-100">
                        <v-img :src="board.thumb" :aspect-ratio="1 / 1" class="w-100" />
                        <v-card-title>
                            <h5 class="font-size-16 font-size-lg-20 ellip--2">
                                {{ board.subject }}
                            </h5>
                        </v-card-title>
                        <template v-if="$route.query.code === 'volunteer' || $route.query.code === 'event'">
                            <v-divider class="my-8 my-lg-16" />
                            <p class="page-text ellip--2">
                                {{ $route.query.code === "event" ? `${board.startAt} - ${board.endAt}` : board.summary }}
                            </p>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <table class="board-list" v-else>
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width: 10%" class="board-list__txt">번호</th>
                    <th style="width: 56.6%" class="board-list__txt">제목</th>
                    <th style="width: 16.7%" class="board-list__txt">작성자</th>
                    <th style="width: 16.7%" class="board-list__txt">작성일</th>
                    <!-- <th style="width:10%;" class="board-list__txt">조회</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(board, idx) in fixed" :key="idx" @click="show(board)">
                    <td align="center" class="board-list__txt">
                        <span class="primary--text">공지</span>
                    </td>
                    <td align="center" class="board-list__tit ellip">
                        <template v-if="code == 'notice'">
                            <v-sheet color="#EEFAE6" height="28" class="px-12 mr-10 d-inline-flex justify-center align-center rounded-pill">
                                <span class="primary--text font-size-14">일반</span>
                            </v-sheet>
                            <v-sheet color="primary lighten-2" height="28" class="px-12 mr-10 d-inline-flex justify-center align-center rounded-pill">
                                <span class="white--text font-size-14">채용</span>
                            </v-sheet>
                        </template>
                        {{ board.subject }}
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ code === "report" ? "법인사무국" : board.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.updatedAt || $dayjs(board.createdAt).format("YYYY-MM-DD") }}
                    </td>
                    <!-- <td align="center" class="board-list__txt">
                        {{board.viewCount}}
                    </td> -->
                </tr>

                <tr v-for="(board, idx) in boards" :key="idx" @click="show(board)">
                    <td align="center" class="board-list__txt">
                        <span> {{ totalCount - ((page - 1) * limit + idx) }}</span>
                    </td>
                    <td align="center" class="board-list__tit ellip">
                        <template v-if="code == 'notice'">
                            <v-sheet color="#EEFAE6" height="28" class="px-12 mr-10 d-inline-flex justify-center align-center rounded-pill">
                                <span class="primary--text font-size-14">일반</span>
                            </v-sheet>
                            <v-sheet color="primary lighten-2" height="28" class="px-12 mr-10 d-inline-flex justify-center align-center rounded-pill">
                                <span class="white--text font-size-14">채용</span>
                            </v-sheet>
                        </template>
                        {{ board.subject }}
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ code === "report" ? "법인사무국" : board.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.updatedAt || $dayjs(board.createdAt).format("YYYY-MM-DD") }}
                    </td>
                    <!-- <td align="center" class="board-list__txt">
                        {{board.viewCount}}
                    </td> -->
                </tr>
            </tbody>
        </table>

        <!-- <div v-else>
            <v-row>
                <v-col v-for="board, idx in boards" :key="idx">
                    <div @click="show(board)">
                        <div>
                            <div class="w-100 pa-20 pa-md-30">
                                <v-row>
                                    <v-col>
                                        {{board.subject}}
                                    </v-col>
                                    <v-col>
                                        {{board.writer.name}}
                                    </v-col>
                                    <v-col>
                                        {{$dayjs(board.createdAt).format("YYYY-MM-DD")}}
                                    </v-col>
                                    <v-col>
                                        {{board.viewCount}}
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div> -->

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" @input="init()" />
            <!-- <a v-if="$route.query.code !== 'reference' && !!write" class="button button--primary" role="link" @click="$router.push(`?code=${filter.code}&&mode=input`)"><span>글쓰기</span></a> -->
        </div>

        <v-sheet color="#FAFAFA" class="pa-18 pa-md-24">
            <v-row justify="center" class="row--xxs">
                <v-col cols="12" md="auto">
                    <v-select class="v-input--small w-100 w-md-100px rounded-pill" placeholder="선택" v-model="filter.searchKey" :items="filterItems" persistent-placeholder dense outlined hide-details item-text="text" item-valuie="value"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field class="v-input--small w-100 w-md-360px rounded-pill" v-model="filter.searchValue" :disabled="filter.searchKey == null" dense outlined hide-details placeholder="검색어를 입력하세요."></v-text-field>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-80px" rounded="pill" color="grey-6" @click="search" dense><span class="white--text font-size-14">검색</span></v-btn>
                </v-col>
            </v-row>
        </v-sheet>

        <div v-if="!!write" class="mt-20 mt-lg-40">
            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey" @click="$router.push(`?code=${filter.code}&&mode=input`)"> 글쓰기 </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components: {},
    props: {
        limit: { type: Number, default: 10 },
    },
    data() {
        return {
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey || "",
                searchValue: this.$route.query.searchValue || "",
                isNotice: true,
            },
            filterItems: ["제목+내용", "제목", "내용"],

            boards: [],
            page: 1,
            pageCount: 0,
            // limit: 10,
            tab: null,
            write: false,

            fixed: [],
            notifications: [],
        };
    },
    computed: {
        code() {
            return this.$route.query.code;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$store.state.accessToken) {
                let { user } = await api.v1.me.get();
                user.isApproved === true ? (this.write = true) : (this.write = false);
            }
            if (this.$route.query.code === "event") {
                let { summary, boards } = await api.v1.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { code: this.$route.query.code, isNotice: true },
                });

                this.totalCount = summary.totalCount;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.boards = boards;
            } else {
                let { summary, boards } = await api.v1.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { code: this.$route.query.code },
                });
                this.totalCount = summary.totalCount;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.boards = boards;
            }
        },
        async search() {
            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0,
                },
                params: { isNotice: true },
            });

            this.fixed = fixed;
            this.notifications = notifications;

            let { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.boards = boards;
            console.log(this.boards);
            console.log((this.page - 1) * this.limit);
        },
        show(board) {
            this.$router.push({ path: `/community/${board._id}` });
        },
        isEvent(state) {
            this.filter.isNotice = state;
            this.search();
        },
    },
    watch: {
        "$route.query.code"() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
.board-contents {
    .v-card {
        border-bottom: 1px solid #ddd;
        transition: all ease-out 0.2s;
        .v-image {
            border-radius: 16px;
            transition: inherit;
        }
        &__title {
            padding: 24px 0 16px;
            transition: inherit;
            line-height: 1.35;
            word-break: keep-all;
        }
        &:hover {
            border-bottom-color: var(--v-primary-base);
            .v-image {
                box-shadow: 0 0 40px 0 rgba(54, 55, 66, 0.3);
            }
            .v-card__title {
                color: var(--v-primary-base);
            }
        }
    }
}
</style>